.ability-display {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 12px;

  --ability-display-height: max(50px, min(11.5vh, 80px, 19vw));
}

.ability-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: var(--ability-display-height);
  background-size: 100% 100%;
  cursor: pointer;
  margin-left: 3px;
  margin-right: 3px;
  aspect-ratio: 212/183;
}

.ability-icon {
  height: calc(var(--ability-display-height) * 0.44);
  aspect-ratio: 189/177;
  margin-bottom: 2px;
  margin-top: 10px;
}

.ability-name {
  color: #ffffff;
  font-size: calc(var(--ability-display-height) * 0.1);
  margin-right: calc(var(--ability-display-height) * 0.1);
  margin-left: calc(var(--ability-display-height) * 0.1);
  text-align: center;
}
