.section-header-container {
  position: relative;
  width: 100%;
  height: 39px;
  margin-top: 20px;
  margin-left: 12px;
  margin-bottom: -6px;
}

.section-header-background {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  overflow: hidden;
}

.section-header-background img {
  height: 100%;
  width: auto;
}

.section-header-text {
  position: absolute;
  left: 13px;
  top: 6px;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
}
