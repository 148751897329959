:root {
  --popup-width: min(90vw, 400px);
  --button-width: calc(var(--popup-width) * 0.35);
  --button-spacing: calc(var(--popup-width) * 0.005);
  --button-bottom-margin: calc(var(--popup-width) * 0.235);
}

.tutorial-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  backdrop-filter: blur(3px);
}

.tutorial-overlay.fade-in {
  opacity: 1;
  visibility: visible;
}

.tutorial-overlay.fade-out {
  opacity: 0;
  visibility: hidden;
}

.tutorial-content {
  width: var(--popup-width);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: scale(0.95);
  transition: transform 0.3s ease;
  will-change: transform;
}

.fade-in .tutorial-content {
  transform: scale(1);
}

.tutorial-slide {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.tutorial-buttons {
  position: absolute;
  bottom: var(--button-bottom-margin);
  display: flex;
  justify-content: center;
  gap: var(--button-spacing);
}

.tutorial-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  width: var(--button-width);
  transition: transform 0.1s ease;
}

.tutorial-button:active {
  transform: scale(0.95);
}

.tutorial-button.disabled {
  cursor: default;
  opacity: 0.5;
}

.button-image {
  width: 100%;
  height: auto;
}
