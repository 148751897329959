.armory-grid {
  width: 100%;
  overflow-y: auto;
  margin-top: 56px;
  padding-bottom: 30px;
}

.armory-grid-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  width: 100%;
}
