.leaderboard-screen {
  --header-height: 80px;
  --user-ranking-height: 88px;
  --total-fixed-height: calc(
    var(--header-height) + var(--user-ranking-height) + 32px
  );

  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  min-height: var(--total-fixed-height);
}

.leaderboard-header {
  align-self: center;
  width: 342px;
  height: var(--header-height);
  margin-bottom: 16px;
  flex-shrink: 0;
}

.user-ranking-container {
  position: relative;
  height: var(--user-ranking-height);
}

.ranking-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-right: -10px;
  margin-left: -10px;
  display: flex;
}

.bg-left,
.bg-right {
  height: var(--user-ranking-height);
  width: auto;
  min-width: 0px;
}

.bg-center {
  height: var(--user-ranking-height);
  flex-grow: 1;
}

.ranking-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  margin-top: 18px;
  margin-bottom: 26px;
  margin-left: 10px;
  margin-right: 10px;
  padding-right: 12px;
  padding-left: 12px;
}

.rank-section {
  font-size: 22px;
  font-weight: bold;
  color: #fff;
  flex-shrink: 0;
  margin-bottom: 2px;
}

.player-section {
  flex: 0 1 auto;
  min-width: 0;
  width: 200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.leaderboard-player-name {
  text-align: center;
  width: 100%;
  font-size: 17px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.stats-container {
  display: flex;
  align-items: center;
  margin-top: -2.5px;
}

.stats-container-elo {
  display: flex;
  align-items: center;
  margin-top: -2.5px;
}

.loot-section {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
}

.elo-icon {
  width: 15px;
  height: 15px;
}

.leaderboard-loot-icon {
  width: 24px;
  height: 24px;
  margin-right: 2px;
}

.stats-value-elo {
  font-family: "Microgramma Bold", sans-serif;
  font-size: 15px;
  color: #fff;
  margin-bottom: 1px;
}

.stats-value {
  font-family: "Microgramma Bold", sans-serif;
  font-size: 15px;
  color: #fff;
  margin-bottom: 2px;
}

.leaderboard-list {
  height: calc(100% - var(--total-fixed-height));
  min-height: 0;
  overflow-y: auto;
}
