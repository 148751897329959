.battle-button-container {
  display: flex;
  position: relative;
  width: 146px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 13px;
}

.battle-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: block;
}

.battle-button-image {
  width: 100%;
  height: auto;
  display: block;
}

.battle-button-line {
  width: 100vw;
  max-width: 356px;
  aspect-ratio: 1285/97;
  pointer-events: none; /* Makes the top bar non-clickable */
  z-index: 1; /* Ensures the line appears above the button */
  margin-bottom: -18px;
}
