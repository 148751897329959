.daily-spoils-day-item {
  --day-item-height: min(99px, 11vh);
  --day-item-padding: min(6.5px, 1vh);
  --icon-size: min(24px, 3.5vh);
  --day-font-size: min(14px, 1.8vh);
  --reward-font-size: min(20px, 2.2vh);

  position: relative;
  display: flex;
  cursor: default;
  transition: transform 0.2s ease;
  height: var(--day-item-height);
}

.daily-spoils-day-item.full-width {
  width: 100%;
}

.daily-spoils-day-item.full-width .item-center {
  flex: 1;
}

.item-left {
  height: var(--day-item-height);
  width: auto;
}

.item-center {
  flex: 1;
  height: var(--day-item-height);
}

.item-right {
  height: var(--day-item-height);
  width: auto;
}

.item-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  pointer-events: none;
}

.day-text {
  font-family: "Microgramma D Extended", sans-serif;
  color: #ffffff;
  font-size: var(--day-font-size);
  margin-top: calc(var(--day-item-height) * 0.065);
  margin-bottom: calc(var(--day-item-height) * 0.04);
}

.loot-icon-daily-spoils {
  height: var(--icon-size);
  width: auto;
}

.reward-text {
  font-family: "Microgramma D Extended", sans-serif;
  color: #96fbff;
  font-size: var(--reward-font-size);
  font-weight: bold;
  margin-top: calc(var(--day-item-height) * -0.06);
}

/* @media screen and (max-width: 380px) {
  .daily-spoils-day-item {
    --day-item-height: min(85px, 11vh);
    --icon-size: min(23px, 3vh);
    --day-font-size: min(13px, 1.8vh);
    --reward-font-size: min(17px, 2.2vh);
  }

  .day-text {
    margin-top: calc(var(--day-item-height) * 0.08);
    margin-bottom: calc(var(--day-item-height) * 0.04);
  }

  .reward-text {
    margin-top: calc(var(--day-item-height) * -0.1);
  }
} */
