.battle-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; /* Distribute space between content and button */
  height: 100%;
}

.battle-screen-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1; /* Allow content to grow and push button to bottom */
  overflow-y: auto; /* Add scrolling if content exceeds screen height */
}
