.loading-container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  padding-top: 33vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: #272767;
  z-index: 10000;
}

.loading-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.initial-loading-text {
  font-family: "Microgramma D Extended", sans-serif;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
}
