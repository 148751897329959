.quests-title-separator {
  display: flex;
  align-items: center;
}

.separator-center {
  flex: 1;
  height: 8px;
  width: 100%;
}

.separator-right,
.separator-left {
  height: 8px;
  max-width: 20px;
}
