.text-input-container {
  display: flex;
  align-items: center;
  position: relative;
  width: calc(100%);
  height: 48px;
}

.text-input {
  caret-color: #ffffff;
  color: #ffffff;
  font-family: "Microgramma Medium", sans-serif;
  font-size: 13px;
  width: calc(100% - 36px);
  height: 30px;
  margin-bottom: 1px;
  padding-left: 18px;
  padding-right: 18px;
  border: none;
  background: transparent;
  position: relative;
  z-index: 1;
}
.text-input::placeholder {
  color: #ffffff; /* Placeholder text color */
}
.text-input:focus {
  outline: none;
}

.text-input-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  pointer-events: none;
}

.text-input-bg-left,
.text-input-bg-right {
  height: 100%;
  width: auto;
}

.text-input-bg-center {
  height: 100%;
  flex-grow: 1;
  font-size: 12px;
}
