.armory-item {
  --armory-item-width: calc(min(44vw, 33vh));
  --armory-item-height: calc(var(--armory-item-width) * 0.619);

  --icon-container-size: calc(var(--armory-item-width) * 0.417);
  --icon-margin-top: calc(var(--armory-item-height) * 0.05);
  --icon-margin-bottom: calc(var(--armory-item-height) * 0.029);
  --icon-margin-left: calc(var(--armory-item-width) * 0.054);
  --icon-margin-right: calc(var(--armory-item-width) * 0.034);
  --icon-size: calc(
    var(--icon-container-size) - var(--icon-margin-top) -
      var(--icon-margin-bottom)
  );

  --header-section-width: calc(
    var(--armory-item-width) - var(--icon-container-size)
  );
  --title-margin-top: calc(var(--armory-item-height) * 0.03);
  --title-margin-bottom: calc(var(--armory-item-height) * 0.023);
  --title-margin-right: calc(var(--header-section-width) * 0.023);
  --title-horizontal-padding: calc(var(--header-section-width) * 0.029);
  --title-section-height: calc(var(--armory-item-height) * 0.268);
  --title-height: calc(
    var(--title-section-height) - var(--title-margin-bottom) -
      var(--title-margin-top)
  );
  --title-width: calc(
    var(--header-section-width) - var(--title-margin-right) -
      (2 * var(--title-horizontal-padding))
  );
  --title-font-size: calc(var(--armory-item-width) * 0.05);
  --title-font-size-small: calc(var(--armory-item-width) * 0.038);
  --title-font-size-medium: calc(var(--armory-item-width) * 0.045);

  --bounty-boost-section-height: calc(var(--armory-item-height) * 0.5);
  --loot-icon-size: calc(var(--armory-item-height) * 0.23);
  --loot-icon-margin-right: calc(var(--armory-item-width) * 0.02);
  --loot-icon-margin-top: calc(var(--armory-item-height) * 0.02);
  --bounty-boost-margin-right: calc(var(--armory-item-width) * 0.04);
  --bounty-boost-margin-left: calc(var(--armory-item-width) * 0.025);
  --footer-height: calc(var(--armory-item-height) - var(--icon-container-size));
  --footer-margin-horizontal: calc(var(--armory-item-width) * 0.06);
  --footer-margin-right-no-level: calc(
    var(--footer-margin-horizontal) - calc(var(--armory-item-width) * 0.005)
  );
  --footer-margin-bottom: calc(var(--armory-item-height) * 0.035);

  width: var(--armory-item-width);
  height: var(--armory-item-height);
  position: relative;
  cursor: pointer;
}

.armory-item-background {
  width: var(--armory-item-width);
  height: var(--armory-item-height);
  display: block;
}

.armory-item-locked-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: var(--armory-item-width);
  height: var(--armory-item-height);
  pointer-events: none;
}

.armory-item-content {
  position: absolute;
  top: 0;
  left: 0;
  width: var(--armory-item-width);
  height: var(--armory-item-height);
  display: flex;
  flex-direction: column;
}

.armory-item-header {
  display: flex;
  flex-direction: row;
  width: var(--header-section-width);
  height: var(--icon-container-size);
}

.item-icon-container {
  width: var(--icon-container-size);
  height: var(--icon-container-size);
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-icon {
  width: var(--icon-size);
  height: var(--icon-size);
  margin-top: var(--icon-margin-top);
  margin-bottom: var(--icon-margin-bottom);
  margin-left: var(--icon-margin-left);
  margin-right: var(--icon-margin-right);
  object-fit: contain;
}

.armory-item-header-copy {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.item-title-container {
  height: var(--title-section-height);
  width: var(--header-section-width);
  align-content: center;
  justify-content: center;
}

.item-title {
  color: white;
  font-family: "Microgramma Bold", sans-serif;
  font-size: var(--title-font-size);
  line-height: 1;
  height: var(--title-height);
  width: var(--title-width);
  margin-top: var(--title-margin-top);
  margin-bottom: var(--title-margin-bottom);
  margin-left: var(--title-horizontal-padding);
  margin-right: var(--title-horizontal-padding);
  align-content: center;
  text-transform: uppercase;
  text-align: center;
}

.item-title.regular-text {
  font-size: var(--title-font-size);
}

.item-title.small-text {
  font-size: var(--title-font-size-small);
}

.item-title.medium-text {
  font-size: var(--title-font-size-medium);
}

.bounty-boost-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: var(--header-section-width);
  flex-grow: 1;
}

.bounty-boost-title {
  color: white;
  font-family: "Microgramma Medium", sans-serif;
  font-size: calc(var(--armory-item-width) * 0.055);
  margin-left: var(--bounty-boost-margin-left);
  display: flex;
  flex-grow: 1;
}

.bounty-boost-value {
  color: white;
  font-family: "Microgramma Bold", sans-serif;
  font-size: calc(var(--armory-item-width) * 0.06);
  flex-grow: 1;
  margin-right: var(--bounty-boost-margin-right);
}

.armory-item-footer {
  display: flex;
  height: var(--footer-height);
}

.footer-content {
  display: flex;
  align-items: center;
  flex: 1;
  color: white;
  font-family: "Microgramma Bold", sans-serif;
  font-size: calc(var(--armory-item-width) * 0.085);
  margin-bottom: var(--footer-margin-bottom);
}

.footer-content.level-hidden {
  justify-content: center;
  margin-right: var(--footer-margin-right-no-level);
}

.footer-content.price-hidden {
  justify-content: center;
  /* margin-right: var(--footer-margin-right-no-level); */
}

.footer-content.level-showing {
  justify-content: space-between;
  margin-left: var(--footer-margin-horizontal);
  margin-right: var(--footer-margin-horizontal);
}

.price {
  display: flex;
  align-items: center;
}

.loot-icon-armory-item {
  width: var(--loot-icon-size);
  height: var(--loot-icon-size);
  margin-right: var(--loot-icon-margin-right);
  margin-top: var(--loot-icon-margin-top);
  object-fit: contain;
}
