.background-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: repeat-x;
  background-position: center;
  background-size: auto 100%;
  z-index: -1;
}
