.stat-display {
  display: flex;
  justify-content: center;
  width: 100%;

  margin-bottom: 4px;
}

.stat-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 35px;
  aspect-ratio: 264/158;
  background-size: 100% 100%;
  padding-left: 0px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 2px;
  margin-left: 2px;
  margin-right: 2px;
  z-index: 1000;
  cursor: pointer;
}

.stat-icon {
  width: 32px;
  height: 32px;
}

.stat-value {
  color: #c1efff;
  font-family: "Microgramma Bold", sans-serif;
  font-size: 16px;
}
