.item-button {
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: url("../assets/images/itemBackground.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  margin-left: 8.5px;
  margin-right: 8.5px;
  margin-bottom: -4px;
}

.item-button:active:not(.disabled) {
  background-image: url("../assets/images/itemBackgroundPressed.png");
}

.item-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.item-button-content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.item-button-title {
  margin-left: 48px;
  color: #fff;
  font-size: 12.5px;
  margin-bottom: 1px;
}
