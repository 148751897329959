.welcome-container {
  --container-width: min(100%, 334px);
  --stat-container-width: calc(var(--container-width) * 0.6);
  --stat-container-margin-top: calc(var(--stat-container-width) * -0.04);

  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: var(--container-width);
  margin: 0 auto;
}

.welcome-logo {
  width: 240px;
  height: auto;
  margin-top: -162px;
}

.welcome-main-text-header {
  width: min(300px, 90%);
  height: auto;
  margin-top: -10px;
  margin-bottom: -8px;
}

.user-name-container {
  position: relative;
  width: 100%;
  display: flex;
  margin-top: -4px;
}

.user-name-text-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 1;
  margin-top: -1px;
}

.user-name-text {
  color: #c1efff;
  font-size: 18px;
  font-family: "Microgramma Medium", sans-serif;
  text-align: center;
}

.welcome-loot-container,
.welcome-elo-container,
.welcome-bounty-boost-container {
  width: var(--stat-container-width);
  position: relative;
  margin-top: var(--stat-container-margin-top);
  margin-right: 24px;
}

.welcome-loot-text-container,
.welcome-elo-text-container,
.welcome-bounty-boost-text-container {
  display: flex;
  width: calc(var(--stat-container-width) * 0.915);
  position: absolute;
  height: calc(var(--stat-container-width) * 0.815);
  z-index: 1;
  align-items: center;
  justify-content: center;
  top: calc(var(--stat-container-width) * 0.38);
  left: calc(var(--stat-container-width) * 0.58);
}

.user-name-container-image,
.welcome-loot-container-image,
.welcome-elo-container-image,
.welcome-bounty-boost-container-image {
  width: 100%;
  height: auto;
}

.welcome-loot-text,
.welcome-elo-text,
.welcome-bounty-boost-text {
  color: white;
  font-size: 17px;
  font-family: "Microgramma Bold", sans-serif;
}

@media screen and (max-width: 375px) {
  .user-name-text {
    font-size: 16px;
  }
}
