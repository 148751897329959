.call-to-action-header {
  position: relative;
  flex: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -4px;
  margin-bottom: -12px;
  width: 100%;
}

.cta-platform {
  width: max(200px, min(326px, 90vw, 42vh));
  height: auto;
}
