.enter-eth-address-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 16px);
  padding-left: 8px;
  padding-right: 8px;
}

.enter-eth-address-title {
  height: auto;
  width: 264px;
  margin-bottom: 0px;
  margin-top: 34px;
}
