.leaderboard-row {
  --row-height: 68px;
  --player-data-height: 68px;

  display: flex;
  align-items: center;
  margin-bottom: -10px;
  margin-left: -1px;
  height: var(--row-height);
}

.rank-number {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  margin-right: 2px;
  width: 44px;
  text-align: right;
}

.player-data {
  position: relative;
  flex-grow: 1;
  height: var(--player-data-height);
  min-width: 0; /* Add this to enable truncation */
}

.row-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: var(--player-data-height);
  display: flex;
}

.row-bg-left,
.row-bg-right {
  height: var(--player-data-height);
  width: auto;
  min-width: 0px;
}

.row-bg-center {
  height: var(--player-data-height);
  flex-grow: 1;
}

.row-content {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  height: var(--player-data-height);
}

.player-details {
  display: flex;
  flex-direction: column;
  min-width: 0; /* Add this to enable truncation */
  flex: 1; /* Add this to make it take available space */
  margin-right: 4px; /* Add space between name and loot */
  margin-left: 8px;
}
.row-name {
  font-size: 16px;
  color: #fff;
  min-width: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.row-elo {
  display: flex;
  align-items: center;
}

.row-elo-text {
  font-size: 14px;
}

.row-elo-icon {
  width: 14px;
  height: 14px;
  margin-right: 2px;
}

.row-loot {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: 8px;
}

.row-loot-text {
  font-size: 15px;
}

.row-loot-icon {
  width: 20px;
  height: 20px;
  margin-right: 3px;
}
