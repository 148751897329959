.quests-screen {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.quest-list {
  display: flex;
  flex-direction: column;
  width: 100%;
}
