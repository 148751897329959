.navigation-bar {
  position: sticky;
  bottom: 0;
  z-index: 10;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: calc(68px + env(safe-area-inset-bottom));
  background-repeat: repeat-x;
  background-position: center;
  background-size: auto 100%;
  width: 100%;
  background-color: #272767;
}

.nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;
  border: none;
  color: #c1efff;
  font-family: "Microgramma Bold", sans-serif;
  font-size: 8.5px;
  margin-bottom: calc(env(safe-area-inset-bottom)-1px);
  position: relative;
}

.nav-item.active {
  color: #ffffff;
}

.nav-icon-container {
  position: relative;
  width: 38px;
  height: 38px;
  cursor: pointer;
}

.nav-icon {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
}

.nav-icon-glow-cloud {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 160%;
  height: 160%;
  z-index: 1;
}

.nav-icon-glow-top {
  position: absolute;
  margin-top: -23px;
  left: 50%;
  transform: translateX(-50%);
  width: 64px;
  z-index: 3;
  aspect-ratio: 31/8;
}

.nav-separator {
  height: 60px;
  aspect-ratio: 19/200;
  opacity: 0.5;
  margin-bottom: env(safe-area-inset-bottom);
}
