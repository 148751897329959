@font-face {
  font-family: "Microgramma Medium";
  src: url("../assets/fonts/Microgramma D Extended Medium.otf")
    format("opentype");
}

@font-face {
  font-family: "Microgramma Bold";
  src: url("../assets/fonts/Microgramma D Extended Bold.otf") format("opentype");
}
