.invite-friend-button {
  flex: 1;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  pointer-events: auto; /* Re-enable pointer events for the button */
}

.invite-left,
.invite-right {
  width: auto;
  object-fit: contain;
  display: block; /* Prevent extra space */
}

.invite-sliver-container {
  flex: 1;
  display: flex;
  align-items: center;
  min-width: 0;
}

.invite-sliver {
  width: 100%;
  object-fit: fill;
  display: block; /* Prevent extra space */
}
