.social-quest-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.social-quest-logo {
  width: auto;
  height: 70px;
  margin-top: 24px;
}

.social-quest-header-text-container {
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
}

.social-quest-header-text {
  font-family: "Microgramma Bold", sans-serif;
  font-size: 24px;
  color: white;
  text-align: center;
}

.social-quest-deeplink-button-container {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.social-quest-deeplink-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.deeplink-button-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.verify-later-text-container {
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.verify-later-text {
  font-family: "Microgramma Bold", sans-serif;
  font-size: 15px;
  color: white;
  text-align: center;
}

.verify-button-container {
  width: 270px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.verify-button {
  background: transparent;
  border: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  appearance: none;
}

.verify-button.clickable {
  cursor: pointer;
}

.verify-button-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.social-quest-deeplink-button:focus,
.verify-button:focus {
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.social-quest-deeplink-button:disabled {
  cursor: default;
  opacity: 0.7;
}

.verify-button:disabled {
  cursor: default;
}

button {
  background: transparent;
  border: none;
  padding: 0;
  -webkit-appearance: none;
  appearance: none;
}

button:disabled {
  background: transparent;
  opacity: 1;
  border: none;
  color: inherit;
}
