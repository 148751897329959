body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #272767;
  color: #fff;
  font-family: "Microgramma Bold", sans-serif;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  /*disable Highlighting*/
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
}

* {
  -webkit-tap-highlight-color: transparent;
}

.app-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.app-content-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.app-top-content-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.background-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.screen-container {
  flex: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.battle-modal {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.battle-modal-content {
  width: 100%;
  height: 100%;

  background-color: #18184a;
  border-radius: 5px;
  position: relative;
}

.unity-game-container {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1001;
}

.copied-message {
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(24, 24, 74, 0.8);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  animation: fadeInOut 1.3s ease-in-out;
  z-index: 1001;
}

.error-message-friend-battle {
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(74, 24, 24, 0.9); /* Darker red background */
  color: #ff9999; /* Light red text */
  padding: 12px 24px;
  border-radius: 5px;
  animation: fadeInOut 1.3s ease-in-out;
  z-index: 1001;
  border: 1px solid #ff4444; /* Red border */
  font-family: "Microgramma Medium", sans-serif; /* Match your app's font */
  text-align: center;
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0;
  }
  10%,
  90% {
    opacity: 1;
  }
}
