.character-display {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6px;
  width: 100%;
  max-width: 100vw;

  --character-cicle-width: max(215px, min(40vh, 280px, 66vw));
  --nav-arrow-height: calc(var(--character-cicle-width) * 0.255);
}

.character-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.circle-character-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: -40px;
  margin-bottom: -36px;
  padding: 0 40px; /* Add padding for the arrows */
}

.nav-arrow-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.nav-arrow-button.left {
  left: calc(-24px + calc((280px - (var(--character-cicle-width)))) * 0.3);
}

.nav-arrow-button.right {
  right: calc(-24px + calc((280px - (var(--character-cicle-width)))) * 0.3);
}

.nav-arrow-button.left img {
  transform: scaleX(-1);
}

.nav-arrow {
  height: var(--nav-arrow-height);
}

.character-circle {
  max-height: 280px;
  width: 100%;
  width: var(--character-cicle-width);
  height: auto;
  pointer-events: none;
  object-fit: contain;
}

.character {
  position: absolute;
  bottom: 0;
  max-width: calc(var(--character-cicle-width) * 0.65);
  width: auto;
  height: auto;
  width: min(
    calc(44vw - 98),
    182px
  ); /* Ensure character doesn't overflow circle */
  margin-bottom: calc(var(--character-cicle-width) * 0.145);
  pointer-events: none;
}

.name-plate {
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 100% 100%;
  margin-top: 11px;
  margin-bottom: 5px;
  aspect-ratio: 355/50;
}

.character-name {
  color: #ffffff;
  font-family: "Microgramma Bold", sans-serif;
  text-shadow: 0px 5px 3.5px rgba(0, 0, 0, 0.5);
  margin-bottom: 5px;
  width: 100vw;
  text-align: center;
}
