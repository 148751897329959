@font-face {
  font-family: "Microgramma D Extended";
  src: url("../assets/fonts/Microgramma\ D\ Extended\ Bold.otf")
    format("opentype");
  font-weight: bold;
  font-style: normal;
}

.loading-view {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 200px; /* Adjust as needed */
  margin-bottom: 30px;
}

.spinner {
  width: 50px; /* Adjust as needed */
  height: 50px;
  margin-bottom: 20px;
}

.loading-text,
.loading-progress {
  font-family: "Microgramma D Extended", sans-serif;
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
  color: white;
}

.loading-text {
  font-size: 24px;
}

.initial-load-text {
  font-size: 14px;
}

.loading-progress {
  font-size: 16px;
  margin-top: 10px;
}
