.armory-screen {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.armory-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
