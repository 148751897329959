.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.popup-overlay.fade-in {
  opacity: 1;
  visibility: visible;
}

.popup-overlay.fade-out {
  opacity: 0;
  visibility: hidden;
}

.popup-content {
  background-image: url("../assets/images/popUpWithIcon.png");
  background-size: 100% 100%;
  width: 330px;
  position: relative;
  aspect-ratio: 139/88;
  transform: scale(0.95);
  transition: transform 0.3s ease;
  will-change: transform;
}

.fade-in .popup-content {
  transform: scale(1);
}

.popup-icon {
  position: absolute;
  width: 49px;
  height: 49px;
  left: 29px;
  margin-top: 26px;
}

.popup-title-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  left: 102px;
  top: 34px;
  height: 40px;
  width: 194px;
}

.popup-title {
  position: relative;
  color: #ffffff;
  font-size: 20px;
}

.popup-body-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 80px;
  height: 69px;
  left: 42px;
  right: 42px;
  margin: 0;
}

.popup-body {
  color: #ffffff;
  font-size: 13px;
  text-align: center;
}
