.header {
  position: sticky;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 102px;
  width: 100%;
  position: relative;
}

.header-side {
  height: 100%;
  width: 100%;
}

.header-full {
  height: 102px;
  width: auto;
}

.header-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 6px 10px;
}

.elo-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.player-level-container {
  width: 100%;
  display: flex;
  align-items: space-between;
  justify-content: space-between;
}

.player-level {
  color: #c1efff;
  font-family: "Microgramma Medium", sans-serif;
  font-size: 10px;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  margin-bottom: 3px;
  padding-left: 1px;
}

.level-bar {
  width: 100%;
  height: 13px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 6.5px;
  overflow: hidden;
}

.level-progress {
  height: 100%;
  background: #c1efff;
}

.player-name {
  color: #c1efff;
  font-family: "Microgramma Medium", sans-serif;
  /*font-size handled dynamically in the component*/
  /*max-width handled dynamically in the component*/
  position: absolute;
  top: 6px;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  text-wrap: nowrap;
}

.loot-amount {
  color: #ffffff;
  font-size: 15px;
  position: absolute;
  top: 37.5px;
  left: 50%;
  transform: translateX(-50%);
}

.bounty-boost {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-stat-value {
  margin-top: 2px;
  display: flex;
  justify-content: center;
}

.header-stat-icon {
  width: 13px;
  height: 13px;
  margin-right: 2px;
}

.header-stat-label {
  color: #c1efff;
  font-family: "Microgramma Medium", sans-serif;
  font-size: 11px;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}

.header-stat-label-bounty-boost {
  color: #c1efff;
  font-family: "Microgramma Medium", sans-serif;
  font-size: 11px;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  margin-top: -2px;
}
