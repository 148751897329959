.daily-spoils-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 16px;
  padding-right: 16px;
  margin: 0 auto;
  width: calc(100% - 32px);
}

.title-image {
  margin-top: 8px;
  width: 240px;
  height: auto;
  margin-bottom: 2px;
}

.rewards-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  width: 100%;
  margin-top: 10px;
}

.rewards-grid > *:nth-child(10) {
  grid-column: 1 / -1;
  width: 100%;
}

.claim-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.claim-button {
  background-color: transparent;
  padding: 0px;
  border-color: transparent;
}

.claim-button-image {
  width: 220px;
  margin-left: -10;
  height: auto;
}

.claim-button.clickable {
  cursor: pointer;
}
