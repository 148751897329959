.info-card {
  position: relative;
  width: 100%;
  height: 69px;
}

.info-card-background {
  display: flex;
}

.info-card-bg-left,
.info-card-bg-right {
  height: 69px;
  width: auto;
}

.info-card-bg-center {
  height: 69px;
  flex-grow: 1;
}

.info-card-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

.info-row-text-container {
  display: flex;
  margin-left: 54px;
  margin-right: 16px;
  margin-top: 1px;
  height: 44px;
  align-items: center;
}

.info-row-text {
  text-align: left;
  font-size: 12px;
  font-family: "Microgramma Medium", sans-serif;
}
