:root {
  --bs-image-height: 121px;
  --bs-image-width: calc(0.9 * var(--bs-image-height));
}

.bottom-sheet-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0);
}

.bottom-sheet-overlay.OPENING {
  animation: fadeIn 0.3s forwards;
}

.bottom-sheet-overlay.OPEN {
  background-color: rgba(0, 0, 0, 0.5);
}

.bottom-sheet-overlay.CLOSING {
  animation: fadeOut 0.3s forwards180;
}

@keyframes fadeIn {
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

@keyframes fadeOut {
  from {
    background-color: rgba(0, 0, 0, 0.5);
  }
  to {
    background-color: rgba(0, 0, 0, 0);
  }
}

.bottom-sheet {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
  transform: translateY(100%);
}

.bottom-sheet.OPENING {
  animation: slideUp 0.3s forwards;
}

.bottom-sheet.OPEN {
  transform: translateY(0);
}

.bottom-sheet.CLOSING {
  animation: slideDown 0.3s forwards;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

.bottom-sheet-background {
  display: flex;
  margin-left: -16px;
  margin-right: -16px;
  flex-direction: column;
  height: 100%; /* Fill full height */
}

.bottom-sheet-top {
  display: flex;
  height: var(--bs-image-height);
}

.bottom-sheet-top-left,
.bottom-sheet-top-right {
  width: var(--bs-image-width);
  height: var(--bs-image-height);
  object-fit: contain;
}

.bottom-sheet-top-center {
  flex-grow: 1;
  height: var(--bs-image-height);
  width: 100%;
  min-width: 0;
}

.bottom-sheet-bottom {
  display: flex;
  flex-grow: 1;
  height: calc(100% - var(--bs-image-height)); /* Subtract top section height */
}

.bottom-sheet-bottom-left,
.bottom-sheet-bottom-right {
  width: var(--bs-image-width);
  height: 100%; /* Fill full height of parent */
  object-fit: fill;
}

.bottom-sheet-bottom-center {
  flex-grow: 1;
  height: 100%; /* Fill full height of parent */
  min-width: 0;
  width: 100%;
}

.bottom-sheet-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: calc(60px + env(safe-area-inset-bottom));
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: none;
}

.bottom-sheet-content-longer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: calc(env(safe-area-inset-bottom));
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: none;
}

.bottom-sheet-buttons {
  position: absolute;
  bottom: calc(20px + env(safe-area-inset-bottom));
  width: calc(100% - 32px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom-sheet-button {
  background: none;
  border: none;
  cursor: pointer;
  width: min(154px, 45%);
  padding: 0;
}

.bottom-sheet-button img {
  width: 100%;
  height: auto;
}

.bottom-sheet-button.disabled {
  cursor: not-allowed;
}

.alt-background-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: repeat-x;
  background-position: center;
  background-size: auto 100%;
  z-index: -1;
}

.alt-background-line-bar {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
}

.alt-background-line-bar img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
