.section-selector-container {
  width: calc(100% - 28px);
  position: relative;
  height: 55px;
  margin-top: 16px;
  margin-bottom: 8px;
  margin-left: 14px;
  margin-right: 14px;
}

.section-selector {
  display: flex;
  width: 100%;
  height: 55px;
  position: absolute;
  top: 0;
  left: 0;
}

.section-selector-left,
.section-selector-right {
  height: 100%;
  width: auto;
  object-fit: contain;
}

.section-selector-middle {
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
}

.section-selector-middle-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: stretch;
}

.section-buttons {
  position: absolute;
  display: flex;
  justify-content: space-between;
  z-index: 1;
  top: 0;
  left: 5px;
  right: 5px;
  height: 55px;
}

.section-button {
  background: none;
  border: none;
  color: #ffffff;
  font-size: 15px;
  font-family: "Microgramma Bold", sans-serif;
  cursor: pointer;
  position: relative;
  width: 150px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-glow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 80%;
  pointer-events: none;
  z-index: -1;
}
